import { Helmet } from "react-helmet";
import axios from "axios";
import FsLightbox from "fslightbox-react";
import React, { useEffect, useState } from "react";
import * as Icon from "react-feather";
import ProgressiveImage from "react-progressive-image";
import Layout from "../components/Layout";
import Sectiontitle from "../components/Sectiontitle";
import Service from "../components/Service";

import Socialicons from "../components/Socialicons";


function Sobre() {
  const [toggler, setToggler] = useState(false);
  const [information, setInformation] = useState("");
  const [services, setServices] = useState([]);

  const handleToggler = (event) => {
    setToggler({
      toggler: !toggler,
    });
  };

  useEffect(() => {
    axios.get("/api/information").then((response) => {
      setInformation(response.data);
    });
    axios.get("/api/services").then((response) => {
      setServices(response.data);
    });
  }, []);

  return (
    <Layout>
      <Helmet>
        <title>Sobre</title>
        <meta
          name="description"
          content="Chester React Personal Portfolio Template About Page"
        />
      </Helmet>
      <div className="mi-about-area mi-section mi-padding-top">
        <div className="container">
          <Sectiontitle title="Sobre" />
          <div className="row">

            <div className="col-lg-12">

              <div className="mi-about-content">
                <h3>
                  Eu sou <span className="color-theme">{information.name}</span>
                </h3>

                <p style={{textAlign: 'justify'}}>
                  <span>{information.aboutContent}</span>
                </p>


              </div>

              <div className="container">
                <div className="row justify-content-center">
                  <div className="col-lg-10 col-12">
                    <div className="mi-home-content">
                      
                      <h4>Redes Sociais</h4>

                      <Socialicons bordered/>

                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
      <div className="mi-service-area mi-section mi-padding-top">
        <div className="container">
          <Sectiontitle title="Serviços" />
          <div className="mi-service-wrapper">
            <div className="row mt-30-reverse">
              {services.map((service) => (
                <div
                  className="col-lg-4 col-md-6 col-12 mt-30"
                  key={service.title}
                >
                  <Service content={service} />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>


      <div className="mi-review-area mi-section mi-padding-top mi-padding-bottom">
        <div className="container">

        </div>
      </div>

      
    </Layout>
  );
}

export default Sobre;