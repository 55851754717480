import React, {  } from "react";
import Particle from "../components/Particle";
import Layout from "../components/Layout";
import Socialicons from "../components/Socialicons";

import Typed from "react-typed";

import { Helmet } from "react-helmet";

function Home({lightMode}){

  const textLinesDevDeskotop = [
    `C# | .Net | Java`,
  ];

  const textLinesDevDesWeb = [
    `HTML5 | CSS3 | JS`,
    `PHP | Bootstrao`,
    `WordPress | React`,
  ];

  const textLinesDevMobile = [
    `Java | Kotlin`,
     `React Native | Flutter`
  ];

  const textLinesNome = [`RONISVONN GOMES`];


  return (
    <Layout>
      
      <Helmet>
        <title>Home</title>
        <meta
          name="description"
          content="Home"
        />
      </Helmet>

      <div className="mi-home-area mi-padding-section">
      <Particle lightMode={lightMode}/>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-10 col-12">
              <div className="mi-home-content">

               <h4>Olá, sou </h4>
                <h2>
                  <strong style={{color: "#037FFF"}}><Typed strings={textLinesNome} typeSpeed={100} backSpeed={30} loop={false} /></strong>
                </h2>

                <h4>
                  <strong style={{color: "#FFFFFF", fontFamily: "Roboto Mono"}}>Developer Full-Stack</strong>
                </h4>

                <h4>
                  <strong style={{color: "#FFFFFF", fontFamily: "Roboto Mono"}}>Desktop: </strong>
                </h4>

                <div>
                  <h4 style={{color: "#DC143C"}}><Typed strings={textLinesDevDeskotop} typeSpeed={60} backSpeed={60} loop={true} /></h4>
                </div>

                <h4>
                  <strong style={{color: "#FFFFFF", fontFamily: "Roboto Mono"}}>Web: </strong>
                </h4>

                <div>
                  <h4 style={{color: "#DC143C"}}><Typed strings={textLinesDevDesWeb} typeSpeed={60} backSpeed={60} loop={true} /></h4>
                </div>

                <h4>
                  <strong style={{color: "#FFFFFF", fontFamily: "Roboto Mono"}}>Mobile: </strong>
                </h4>

                <div>
                  <h4 style={{color: "#DC143C"}}><Typed strings={textLinesDevMobile} typeSpeed={60} backSpeed={60} loop={true} /></h4>
                </div>

                <br/>                
                <Socialicons bordered/>

              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default Home;