import Mock from "../mock";

const database = {
  information: {
    name: 'RONISVONN GOMES',
    aboutContent: "Sou Desenvolvedor Full-Stack, graduado em Análise e Desenvolvimento de Sistemas no ano de 2021 pela Universidade Cruzeiro do Sul - SP - Brasil. Sou Apaixonado por tecnologia desde sempre, em busca de novos conhecimentos a cada dia.",
    socialLinks: {
      facebook: '',
      twitter: '',
      pinterest: '',
      behance: '',
      linkedin: 'https://www.linkedin.com/in/ronisvonn-gomes-82ab6ab0/',
      dribbble: '',
      github: 'https://github.com/RONISVONN',
      email: 'mailto:contato@rgssoftwarebr.com.br',
      stackoverflow: 'https://stackoverflow.com/users/16215153/ronisvonn-gomes?tab=profile'
    },
    brandImage: '/images/foto_perfil.png',
    aboutImage: '/images/about-image.jpg',
    aboutImageLg: '/images/about-image-lg.jpg',
    cvfile: '/files/empty.pdf'
  },
  services: [
    {
      title: "Desktop",
      icon: 'display',
      details: "Desenvolvimento de aplicações para Desktop - Windows",
      linguagem: "C# / Java",
      db: "Sql / MySql"
    },
    {
      title: "Web",
      icon: 'chrome',
      details: "Desenvolvimento de aplicações para Web - Site Responsivo",
      linguagem: "HTML5/CSS3/\nBootstrap/JavaScript/WordPress/React",
      db: "Sql / MySql"
    },
    {
      title: "Mobile",
      icon: 'mobile',
      details: "Desenvolvimento de aplicações para Mobile - (Android / IOs)",
      linguagem: "Android - Java / Kotlin",
      db: "MySql"
    }
  ],

  skills: [
    {
      title: "C# | .Net",
      value: 90
    },
    {
      title: "Java",
      value: 80
    },
    {
      title: "PHP",
      value: 70
    },
    {
      title: "HTML5",
      value: 90
    },
    {
      title: "CSS3",
      value: 90
    },
    {
      title: "Javascript",
      value: 70
    },
    {
      title: "jQuery",
      value: 70
    },
    {
      title: "Bootstrap",
      value: 80
    },
    {
      title: "WordPress",
      value: 90
    },
    {
      title: "Photoshop",
      value: 60
    },
    {
      title: "Flutter",
      value: 60
    },
    {
      title: "ReactJS",
      value: 60
    }
  ],

  portfolios: [
    {
      id: 1,
      title: "RAQUEL MODAS & CONFECÇÕES ",
      subtitle: "Site - E-Commerce - WordPress",
      imageUrl: "/images/raquel-modas.gif",
      largeImageUrl: ["/images/raquel-modas.gif"],
      url: 'https://raquelmodasconfeccoes.com.br/'
    },
    {
      id: 2,
      title: "Cadastro de Clientes",
      subtitle: "App - Mobile - Java",
      imageUrl: "/images/mobile-cadastro-clientes.gif",
      largeImageUrl: ["/images/mobile-cadastro-clientes.gif"],
      url: 'https://play.google.com/store/apps/details?id=ronisvonn.gomes.cadastrodeclientes'
    },
    {
      id: 3,
      title: "Crud Cliente",
      subtitle: "C# ASP .NET Core 5 - MVC - Entity Framework",
      largeImageUrl: ["/images/crud_cad_cliente_dotnet.gif"],
      imageUrl: "/images/crud_cad_cliente_dotnet.gif",
      url: 'https://crud-cliente-asp-net-core-5.azurewebsites.net/'
    },
    {
      id: 4,
      title: "Sistema de Gerenciamento de Auto Peças",
      subtitle: "Sistema em C# .Net | DB-MySQL",
      largeImageUrl: ["/images/demo_csharp_mclaren.gif"],
      imageUrl: "/images/demo_csharp_mclaren.gif",
      url: ''
    },
    {
      id: 5,
      title: "Api Cep Br",
      subtitle: "C# ASP .NET Core 6 - MVC - Entity Framework",
      largeImageUrl: ["/images/api_cep_br.gif"],
      imageUrl: "/images/api_cep_br.gif",
      url: 'https://ronisvonn-api-cep-br.azurewebsites.net/swagger/index.html'
    }
  ],
  
  experience: {

    educationExperience: [
      {
        id: 1,
        year: "2018 - 2021",
        graduation: "Análise e Desenvolvimento de Sistemas",
        university: "Universidade Cruzeiro do Sul",
        details: "- Analista de sistemas Profissional que analisa e desenvolve softwares e programas. Mapeia processos e realiza modelagem de dados, com o objetivo de estudar e implementar sistemas.",
        details1: "",
        details2: "",
        link: "https://siaa.cruzeirodosul.edu.br/consulta-diploma/resultado/index.jsf;jsessionid=7UTMOpXXIClQtW76MpLyQdmdn7xdgQ0KHz4oxeI4.mianmar04:srv-media-A04"
      },
      {
        id: 2,
        year: "2016 - 2018",
        graduation: "Técnico em Informática",
        university: "Senac Brasil",
        details: "- Qualificação Profissional Técnica de Assistente de Suporte e Manutenção de Computadores",
        details1: "- Qualificação Profissional Técnica de Assistente de Operação de Redes de Computadores",
        details2: "- Qualificação Profissional Técnica de Assistente de Desenvolvimento de Aplicativos Computacionais"
      },
      {
        id: 3,
        year: "2015 - 2016",
        graduation: "Técnico em Redes de Computadores",
        university: "IBTA - Instituto Brasileiro de Tecnologia Avançada",
        details: "- Qualificação Profissional Técnica de Assistente de Operação de Redes de Computadores",
        details1: "",
        details2: ""
      }
    ],
    cursos: [
      {
        id: 1,
        year: "2021 - Online",
        curso: "Desenvolvimento Android Completo 2021 - Crie 18 Apps",
        instrutor: "Profº. Jamilton Damasceno",
        link: "https://www.udemy.com/certificate/UC-ab7eed34-29c9-4081-a7cd-d5e17d01e64c/",
        company: "Udemy",
        details: ""
      },
      {
        id: 2,
        year: "2020 - Online",
        curso: "Curso completo de Kotlin para Android Curso completo de Kotlin para Android",
        instrutor: "Profº. Jamilton Damasceno",
        link: "https://www.udemy.com/certificate/UC-ffda18d2-0d23-49d2-89ec-aa83326aae0e/",
        company: "Udemy",
        details: ""
      },
      {
        id: 3,
        year: "2020 - Online",
        curso: "Desenvolvimento Android e IOS com Flutter 2020 -Crie 15 Apps",
        instrutor: "Profº. Jamilton Damasceno",
        link: "https://www.udemy.com/certificate/UC-c4be559f-86f2-4fc8-9847-b91078f9d565/",
        company: "Udemy",
        details: ""
      },
      {
        id: 4,
        year: "2020 - Online",
        curso: "Desenvolvimento Web Completo 2021 - 20 cursos + 20 projetos",
        instrutor: "Profº. Jamilton Damasceno",
        link: "https://www.udemy.com/certificate/UC-6cebc394-804d-4f2f-a537-caca7774b2dc/",
        company: "Udemy",
        details: ""
      },
      {
        id: 5,
        year: "2020 - Online",
        curso: "Desenvolvimento Android - Crie 13 apps completos e modernos",
        instrutor: "Profº. Marco Maddo",
        link: "",
        company: "Udemy",
        details: ""
      },
      {
        id: 6,
        year: "2020 - Online",
        curso: "Curso Completo de PHP 7",
        instrutor: "Hcode",
        link: "https://www.udemy.com/certificate/UC-ec40733c-ff4a-4c92-8c9f-714559d5f818/",
        company: "Udemy",
        details: ""
      },
      {
        id: 7,
        year: "2020 - Online",
        curso: "C# COMPLETO 2020 Programação Orientada a Objetos + Projetos",
        instrutor: "Profº. Nelio Alves",
        link: "https://www.udemy.com/certificate/UC-2eafd2fc-467a-4a5e-bf4c-b050a6188a9a/",
        company: "Udemy",
        details: ""
      }
    ],
  },

  blogs: [
    {
      id: 1,
      title: 'Modelo MVC - PHP',
      featuredImage: '/images/modelo-mvc-php.png',
      filesource: '../../blog/modelo_mvc_php.md',
      createDay: "30",
      createMonth: 'June',
      createYear: "2021"
    },
    {
      id: 2,
      title: 'Bootstrap 5 declara o Fim do JQuery 😱😱😱',
      featuredImage: '/images/bootstrap_5.png',
      filesource: '../../blog/bootstrap_5_declara_fim_jquery.md',
      createDay: "30",
      createMonth: 'June',
      createYear: "2021"
    }
  ],

  contactInfo: {
    phoneNumbers: ['(11) 95197-1384'],
    emailAddress: ['contato@rgssoftwarebr.com.br', 'ronisvonn@gmail.com']
  }
}


Mock.onGet("/api/information").reply(config => {
  const response = database.information;
  return [200, response];
});

Mock.onGet("/api/services").reply(config => {
  const response = database.services;
  return [200, response];
});

Mock.onGet("/api/reviews").reply(config => {
  const response = database.reviews;
  return [200, response];
});

Mock.onGet("/api/skills").reply(config => {
  const response = database.skills;
  return [200, response];
});

Mock.onGet("/api/portfolios").reply(config => {
  const response = database.portfolios;
  return [200, response];
});

Mock.onGet("/api/experience").reply(config => {
  const response = database.experience;
  return [200, response];
});

Mock.onGet("/api/blog").reply(config => {
  const response = database.blogs;
  return [200, response];
});

Mock.onGet("/api/contactinfo").reply(config => {
  const response = database.contactInfo;
  return [200, response];
});