import { Helmet } from "react-helmet";
import React, { useState, useEffect } from "react";
import axios from "axios";
import * as Icon from "react-feather";
import Sectiontitle from "../components/Sectiontitle";
import Layout from "../components/Layout";
import emailjs from "emailjs-com";

require('dotenv').config();

function Contato() {
  const [phoneNumbers, setPhoneNumbers] = useState([]);
  const [emailAddress, setEmailAddress] = useState([]);
  const [address, setAddress] = useState([]);
  const [formdata, setFormdata] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });
  const [error, setError] = useState(false);
  const [message, setMessage] = useState("");

  const submitHandler = (event) => {
    event.preventDefault();
    if (!formdata.name) {
      setError(true);
      setMessage("Nome é obrigatório");
    } else if (!formdata.email) {
      setError(true);
      setMessage("Email é obrigatório");
    } else if (!formdata.subject) {
      setError(true);
      setMessage("O Assunto é obrigatório");
    } else if (!formdata.message) {
      setError(true);
      setMessage("A Mensagem é obrigatório");
    } else {

      //Se não tiver Erro
      setError(false);

      emailjs.sendForm(process.env.REACT_APP_EMAIL_SERVICE, process.env.REACT_APP_EMAIL_TEMPLATE, event.target, process.env.REACT_APP_EMAIL_USER)

      .then((result) => {

          //Mensagem de envio
          setMessage('Sua mensagem foi enviada com Sucesso!!!');

          //Limpar os Campos 
          setFormdata({
            name: '',
            email: '',
            subject: '',
            message: ''
          });
                        
          //Retira a 'Mensagem de envio' após 5 segundos
          setTimeout(()=>{
            setMessage('');
          }, 5000);     

         
      }, (error) => {

        //Mensagem de envio
        setMessage('Algo deu errado sua mensagem não foi enviada !!!');

        //Limpar os Campos 
        setFormdata({
          name: '',
          email: '',
          subject: '',
          message: ''
        });
                        
        //Retira a 'Mensagem de envio' após 5 segundos
        setTimeout(()=>{
          setMessage('');
        }, 5000); 
          
      });
      event.target.reset()
    }
  };
  
  const handleChange = (event) => {
    setFormdata({
      ...formdata,
      [event.currentTarget.name]: event.currentTarget.value,
    });
  };

  const numberFormatter = (number) => {
    const phnNumber = number;
    return phnNumber;
  };

  const handleAlerts = () => {
    if (error && message) {
      return <div className="alert alert-danger mt-4">{message}</div>;
    } else if (!error && message) {
      return <div className="alert alert-success mt-4">{message}</div>;
    } else {
      return null;
    }
  };

  useEffect(() => {
    axios.get("/api/contactinfo").then((response) => {
      setPhoneNumbers(response.data.phoneNumbers);
      setEmailAddress(response.data.emailAddress);
      setAddress(response.data.address);
    });
  }, []);

  return (
    <Layout>
      <Helmet>
        <title>Contato</title>
        <meta
          name="description"
          content="Página de Contato"
        />
        
      </Helmet>
      <div className="mi-contact-area mi-section mi-padding-top mi-padding-bottom">
        <div className="container">
          <Sectiontitle title="Formulário de Contato" />
          <div className="row">
            <div className="col-lg-6">
              <div className="mi-contact-formwrapper">
                <h4>Entre em contato</h4>
                <form
                  action="#"
                  className="mi-form mi-contact-form"
                  onSubmit={submitHandler}
                >
                  <div className="mi-form-field">
                    <label htmlFor="contact-form-name">Digite seu nome*</label>
                    <input
                      onChange={handleChange}
                      type="text"
                      name="name"
                      id="contact-form-name"
                      value={formdata.name}
                    />
                  </div>
                  <div className="mi-form-field">
                    <label htmlFor="contact-form-email">
                      Digite seu e-mail*
                    </label>
                    <input
                      onChange={handleChange}
                      type="text"
                      name="email"
                      id="contact-form-email"
                      value={formdata.email}
                    />
                  </div>
                  <div className="mi-form-field">
                    <label htmlFor="contact-form-subject">
                      Digite seu assunto*
                    </label>
                    <input
                      onChange={handleChange}
                      type="text"
                      name="subject"
                      id="contact-form-subject"
                      value={formdata.subject}
                    />
                  </div>
                  <div className="mi-form-field">
                    <label htmlFor="contact-form-message">
                      Digite sua menssagem*
                    </label>
                    <textarea
                      onChange={handleChange}
                      name="message"
                      id="contact-form-message"
                      cols="30"
                      rows="6"
                      value={formdata.message}
                    ></textarea>
                  </div>
                  <div className="mi-form-field">
                    <button className="mi-button" type="submit">
                      ENVIAR
                    </button>
                  </div>
                </form>
                {handleAlerts()}
              </div>
            </div>


            <div className="col-lg-6">
              <div className="mi-contact-info">
                {!phoneNumbers ? null : (
                  <div className="mi-contact-infoblock">
                    <span className="mi-contact-infoblock-icon">
                      <Icon.Phone />
                    </span>
                    <div className="mi-contact-infoblock-content">
                      <h6>Celuar - WhatsApp</h6>
                      {phoneNumbers.map((phoneNumber) => (
                        <p key={phoneNumber}>

                          <a href={numberFormatter(phoneNumber)}>
                            {phoneNumber}
                          </a>

                        </p>
                      ))}
                    </div>
                  </div>
                )}
                {!emailAddress ? null : (
                  <div className="mi-contact-infoblock">
                    <span className="mi-contact-infoblock-icon">
                      <Icon.Mail />
                    </span>
                    <div className="mi-contact-infoblock-content">
                      <h6>Email</h6>
                      {emailAddress.map((email) => (
                        <p key={email}>
                          <a href={`mailto:${email}`}>{email}</a>
                        </p>
                      ))}
                    </div>
                  </div>
                )}

              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
    
  );
}

export default Contato;