import React, {  } from "react";
import Layout from "../components/Layout";
import Socialicons from "../components/Socialicons";

import { Helmet } from "react-helmet";

function PoliticaDePrivacidade({lightMode}){

  return (
    <Layout>
      
      <Helmet>
        <title>Política de Privacidade</title>
        <meta
          name="description"
          content="Política de Privacidade"
        />
      </Helmet>

      <div className="mi-home-area mi-padding-section">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-10 col-12">
              <div className="mi-home-content">

               <h4>Política de Privacidade</h4>
               
               <Socialicons bordered/>

              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default PoliticaDePrivacidade;