import React from "react";

function Resume(props) {
  const {year, curso, link, instrutor, graduation, university, company, details, details1, details2} = props.resumeData;
  return (
    <div className="mi-resume mt-30">
      <div className="mi-resume-summary">
        <h6 className="mi-resume-year">{year}</h6>
      </div>
      <div className="mi-resume-details">
        <h5>{curso || graduation}</h5>
        <h6>{instrutor}</h6>

        {/*
        <a href={`${link}`} class="active">Link Certificado de Conclusão</a>        
        */}

        <h4 className="mi-resume-company">{company || university}</h4>
        <p>{details}</p>
        <p>{details1}</p>
        <p>{details2}</p>
      </div>
    </div>
  );
};

export default Resume;